<template>
   <div class="d-block" :class="{ '': inCard, 'mt-4': !inCard,  }">    
      <b v-if="inCard" class="x-small font-weight-semibold lightgrey-color-a2">Operazioni di importanza strategica</b>
      <h4 v-else-if="!inCard" id="obiettivi">Operazioni di importanza strategica</h4>
      
      <div class="d-block" v-if="inCard">
        <template v-if="progettoFallback.length == progetto.length">
          <template v-for="(psItem, index) in progetto" :key="`ps-${index}`">
            <Chip :tagTitle="psItem.titolo" :isTag="isTag" :url="psItem.contenutoLink"/>
          </template>
        </template>
        <template v-if="progettoFallback.length != progetto.length">
          <template v-for="(psItem, index) in progettoFallback" :key="`ps-${index}`">
            <Chip :tagTitle="psItem" :isTag="isTag" :url="parentUrl(psItem)" />
          </template>
        </template>
      </div>
      <div class="d-block" v-else>
        <template v-for="(psItem, index) in progetto" :key="`ps-${index}`">
          <Chip :tagTitle="getTitle(psItem.flatData)" :isTag="isTag"  :url="getContentPadrePath(psItem)"/>          
        </template>
      </div>         
   </div>
</template>

<script>
   import Chip from '@/components/Chip';   
   export default {
      name: "ProgettiStrategici",
      components:{
         Chip
      },
      props: {
         inCard: {
           type: Boolean,
           default: false,
         },
         isTag: {
           type: Boolean,
           default: false,
         },         
         progetto: {
           type: [String, Array, Object],
           description: "Link type"
         },
         progettoFallback: {
           type: [String, Array, Object],
           description: "Link type"
         }
      },
      methods:{
        getTitle(obj){
          return obj.titolo;
        },

        getContentPadrePath(obj){          
          let path = ''
          let pathArr = []

          pathArr.push(obj.flatData.slug)
          if(obj.__typename !== undefined){
            pathArr.push(obj.__typename.match(/[A-Z][a-z]+|[0-9]+/g).join("-").toLowerCase())
          }
          
          if(obj.flatData.contenutoPadre !== undefined && obj.flatData.contenutoPadre[0] !== undefined){
            pathArr.push(obj.flatData.contenutoPadre[0].flatData.slug)

            if(obj.flatData.contenutoPadre[0].flatData.contenutoPadre !== null && obj.flatData.contenutoPadre[0].flatData.contenutoPadre !== undefined){
              pathArr.push(obj.flatData.contenutoPadre[0].flatData.contenutoPadre[0].flatData.slug)
              
                if(obj.flatData.contenutoPadre[0].flatData.contenutoPadre[0].flatData.contenutoPadre !== null && obj.flatData.contenutoPadre[0].flatData.contenutoPadre[0].flatData.contenutoPadre !== undefined){
                  pathArr.push(obj.flatData.contenutoPadre[0].flatData.contenutoPadre[0].flatData.contenutoPadre[0].flatData.slug)
                }                
            }
          }      

          path = '/' + pathArr.reverse().join('/');
          return path;
        },

        parentUrl(str){
          return this.$route.path + "?progetti="+str
        }

      }
   };
</script>