<template>
   <div class="form-group mb-4">
      <h6 class="small font-weight-bold mb-0" v-if="titleDropdown">{{ titleDropdown }}</h6>

      <div class="dropdown">
         <div class="input-group mb-3">
            <input type="text" placeholder="cerca..." v-model="searchTerm" class="form-control form-control-sm">
            <div class="input-group-append">
               <button class="btn btn-sm " type="button" @click="toggleDropdown">
                  <svg class="icon icon-sm mr-1">
                     <use xlink:href="@/assets/img/sprite.svg#it-expand"></use>
                  </svg>
               </button>
            </div>
         </div>
         <ul class="dropdownTextSelect small p-0" v-show="showDropdown" ref="dropDown">
            <li class="dropdown-item px-1 py-2 text-wrap small" ref="listitemall" @click="resetFilterSelected">TUTTI</li>
            <template v-for="(item, index) in items.buckets" :key="`responsabile_${index}`">
               <li v-if="filteredSelectedVals.includes(this.$props.title + ':' + item.key)" class="dropdown-item px-1 py-2 text-wrap small disabled" ref="listitem">{{ item.key }}</li>
               <li v-else class="dropdown-item px-1 py-2 text-wrap small" @click="selectItem(item.key)" ref="listitem">{{ item.key }}</li>
            </template>
         </ul>
      </div>

      <div class="mt-2" v-if="filteredSelectedVals.length > 0">
         <template v-if="typeof filteredSelectedVals !== 'string'">
            <template v-for="(item, index) in filteredSelectedVals" :key="`chip_${index}`">
               <ComponentChipEliminable :isTag="false" :isEliminable="true" isProgram="false" :tagTitle="item" @remove-chip="removeChip" />
            </template>
         </template>
         <template v-else>
            <ComponentChipEliminable :isTag="false" :isEliminable="true" isProgram="false" :tagTitle="filteredSelectedVals" @remove-chip="removeChip" />
         </template>
      </div>
   </div>
</template>
<script>
import ComponentChipEliminable from '@/components/ComponentChipEliminable';
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

export default {
   name: "selectDropDown",

   components: {
      ComponentChipEliminable
   },

   props: {
      title: {
         type: String,
         required: false
      },
      items: {
         type: Object
      },
      filterpreselect: {
         type: Array,
         required: false
      }
   },

   data() {
      return {
         selectedItem: this.$props.filterpreselect ? this.$props.filterpreselect : [],
         filteredSelectedVals: [],
         searchTerm: "",
         showDropdown: false,
      }
   },


   methods: {
      toggleDropdown() {
         this.showDropdown = !this.showDropdown;
      },

      selectItem(item) {
         this.selectedItem = item;
         this.filteredSelectedVals.push(item)
         this.showDropdown = false;
         this.$emit('addSelectFilter', this.$props.title + ':' + item)
      },

      resetFilterSelected() {
         this.filteredSelectedVals = [];
         this.showDropdown = false;
         //      this.$emit('resetToAll', this.$props.title)
      },

      removeChip(val) {
         if (typeof this.filteredSelectedVals == 'string') {
            this.filteredSelectedVals = [];
         }
         else {
            this.filteredSelectedVals = this.filteredSelectedVals.filter(function (item) {
               return item !== val
            })
         }
         this.$emit('removeSelectFilter', this.titleDropdown + ':' + val)
      },

      setup() {
         const dropDown = ref(null)
         onClickOutside(
            dropDown,
            (event) => {
               console.log(event)
            }
         )
         return { dropDown }
      },

      updateSelectedItem() {
         for (let filterVal of this.$props.filterpreselect) {
            if (filterVal.indexOf(this.$props.title + ":") > -1) {
               this.filteredSelectedVals.push(filterVal)
            }
         }
      }

   },

   mounted() {
      if (this.$props.filterpreselect.length > 0) {
         for (let filterVal of this.$props.filterpreselect) {
            this.selectedItem = filterVal.slice(filterVal.indexOf(':') + 1)
         }
         this.filteredSelectedVals = this.selectedItem
      }
   },

   updated() {
      // this.updateSelectedItem();
   },

   watch: {
      searchTerm: {
         handler(newValue) {
            let list = this.$refs.listitem;

            if (newValue === '') {
               this.showDropdown = false
               this.$refs.listitemall.classList.remove('hidden')
               for (let li of list) {
                  li.classList.remove('hidden')
               }
            }
            else {
               this.showDropdown = true
               this.$refs.listitemall.classList.add('hidden')
               list.filter(li => {
                  if (!li.innerHTML.toLowerCase().includes(newValue.toLowerCase())) {
                     li.classList.add('hidden')
                  }
                  else {
                     li.classList.remove('hidden')
                  }
               })
            }
         }
      }
   },

   computed: {
      titleDropdown() {
         if (this.$props.title == 'SERVIZIO_RESPONSABILE') {
            return 'STRUTTURA DI RIFERIMENTO'
         }
         else {
            return this.$props.title.replaceAll('_', ' ');
         }
      }
   },

}
</script>