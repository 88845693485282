<template>
   <transition name="modal-fade">
      <form class="search-area filterForm" v-on:submit.prevent="onSubmit" >
         <div
            ref="modal"
            class="modal fade"
            :class="{show, 'd-block': active}"
            tabindex="-1"
            role="dialog"
            >
            <div class="modal-dialog modal-xl m-1 m-md-auto" role="document">
              <div class="modal-content p-4">
                  <div class="modal-header p-0 p-sm-2">
                     <div class="w-100 d-block clearfix" id="formSearchGeneric">                       
                        <h4 class="h2">Cerca</h4>

                        <div class="input-group-append position-relative">
                           <label for="field-cerca" class="sr-only d-none active" style="transition: none 0s ease 0s;">Cerca</label>
                           <input type="search" name="q" id="field-cerca" placeholder="Cerca" class="form-control mt-auto" v-model="keywords"/>

                            <div class="input-group-append resetArea generic">
                               <button class="btn no-radius btn-minimal-padding reset" type="reset" name="resetRicerca" area-label="Resettare la ricerca">
                                  <svg class="icon icon-sm"><use xlink:href="@/assets/img/sprite.svg#it-close-big"></use></svg>
                               </button>
                            </div>
                            <button class="btn btn-primary btn-minimal-padding" type="submit" name="inviaRicerca" area-label="Invia la ricerca">
                              <svg class="icon icon-white icon-sm"><use xlink:href="@/assets/img/sprite.svg#it-search"></use></svg>
                            </button>
                        </div>
                     </div>

                     <div class="d-block clearfix">
                        <button class="close p-1 p-md-3" type="button" data-dismiss="modal" aria-label="Chiudi" @click="toggleModal" name="chiudiModal">
                           <svg class="icon icon-lg"><use xlink:href="@/assets/img/sprite.svg#it-close"></use></svg>
                        </button>     
                     </div>                                        
                  </div>
               </div>
            </div>
         </div>
         <div v-if="active" class="modal-backdrop fade show"></div>
      </form>
   </transition>
</template>
<script>
   export default {
      name: "searchGeneric",

      components: { 
      },  

      props: {
         title:{
            type: String,
            description: "titolo della ricerca"
         },
         full:{
            type: Boolean,
            default: false            
         }               
      },

      data() {
         return {
            active: false,
            show: false,
            q: '',
            keywords: "",
         };
      },
      
      mounted(){
      },

      methods: {
         toggleModal() {
            const body = document.querySelector("body");
            this.active = !this.active;
            this.active
              ? body.classList.add("modal-open")
              : body.classList.remove("modal-open");
            setTimeout(() => (this.show = !this.show), 10);
         },

         onSubmit(){
            this.$router.push({  name: 'Cerca' , 
                                 query: {
                                    q : this.keywords,                                    
                                 }
                              });             
            this.toggleModal();
         } 
      }
   };

</script>
<style scoped>
#filterTabs .nav-item .nav-link{ width:100%; }
.search-area .modal .modal-content{ min-height:300px; }

input[type="search"]:placeholder-shown + .input-group-append > .reset {
    display: none;
}

.close{position:absolute;right: 40px;top: 30px;}
input[type="checkbox"]{margin-right: 0.5rem;}

.btn-group{ display:inline-block; }
.btn.btn-chip:hover:not(.chip-disabled) {
   background: #F2F2F2;
   border-color: #005c99;
   transition: background-color 0.1s;
   text-decoration: none;
   font-weight: 600;
}

.btn.btn-chip {
   margin-bottom: 6px;
   display: none;
   justify-content: space-evenly;
   align-items: center;
   background: #fff;
   border: 1px solid #005c99;
   border-radius: 12px !important;
   color: #000;
   height: 24px;
   min-width: 100px;
   padding: 4px 32px 4px 32px !important;
   transition: all 0.05s;
   margin-right: 4px;
   font-size: 0.77778rem;
   text-decoration: none;
   font-weight: 600;
   line-height: 1.0rem;
   height: min-content;
   white-space: nowrap;
   box-shadow: unset !important;
}
    
.btn.btn-chip > span {
   font-size: 0.778rem;
   height: 16px;
   font-weight: 600;
   color: #000 !important;
   margin-bottom: 0;
   transform: translateY(-2px);
   transition: color 0.05s;
   text-decoration: none;    
   margin-left: -6px;
 }

.btn.btn-chip > span:after{
   content: "x";
   position: relative;
   right: -6px;
   top: -1px;
   font-weight: normal;
   color: #666;
   transform:scale(1.25, 1.1);
   cursor: pointer;
 }
.btn.btn-chip.active {
   display: inline-block;
}
</style>
