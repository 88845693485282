<template>
   <div class="row">
      <template v-for="(item, index) in items" :key="index">
        {{item}}
      </template>
      <!--BaseCard v-for="(item, index) in items.hits" :key="index" :tipologia="item.tipoContenuto.toLowerCase()" :item="item" /-->
   </div>
</template>
<script>
  // import BaseCard from '@/components/cards/BaseCard.vue';   
   import {searchFiltered} from '@/service/search.js';

   export default {
      name: "BloccoListaFiltrato",
      components:{
   //      BaseCard
      },
      data(){
        return {
          items: [],
          who:  '',
          sort:  '',
          sortDir:  'ASC',
          from:  0,
          limit:  3
        }
      },
      props: {
         articles: Object,
         parentUrl: String,
      },

      async created(){
        for(const filter of this.$props.articles.filtriList){
          if(filter.campoFiltro == 'tipoContenuto'){ this.who = filter.valoreFiltro }
          if(filter.campoFiltro == 'limit'){ this.limit = filter.valoreFiltro }          
          if(filter.campoFiltro == 'sort'){ this.sort = filter.valoreFiltro }
          if(filter.campoFiltro == 'sortValue'){ this.sortDir = filter.valoreFiltro }
        }

        this.sort = [{"data.dataPubblicazione.iv": {"order": this.sortDir }}]

        this.items = await searchFiltered(this.who,this.sort, 0, this.limit)
      }
   }
</script>
<style scoped>
</style>
