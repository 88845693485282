<template>
   <div class="row">
      <div class="col-12 col-md-12 col-lg-6 col-xl-4 py-2">
         <h2>BloccoListaContentSlider</h2>
      </div>
   </div>
</template>
<script>
   export default {
      name: "BloccoListaContentSlider",
      props: {
         articles: Object,
         parentUrl: String
      },
      computed:{
      }, 
   };
</script>
<style scoped>
</style>
