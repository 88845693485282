<template>
   <div class="row" v-if="articles[0].items !== undefined">
      <div v-for="(item, index) in articles" :key="index" class="pt-3 mt-2 mt-sm-0 pb-1 col-12">
         <div :style="[`background: ${backGroundColorComp}; color:${fontColorComp};`]">
            <h3 class="h4 py-2">{{item.titolo}}</h3>

            <div class="row">
               <div class="col-12 col-md-12 col-lg-8 raccolta-image" v-if="item.img">
                  <img v-lazy="item.img[0].imgUrl+'&cache=31536000'" :alt="item.img[0].nome" class="w-100" width="800" height="320"/>
               </div>

               <div class="col-12 col-md-12 col-lg-4">
                  <ul class="raccolta"
                      :class="[
                          {['columns'] : item.items.length > 5 }
                        ]">
                     <li v-for="(itemChild, index) in item.items" :key="index" class="col-12"
                        :class="[
                          {['col-sm-6'] : item.items.length > 5 }
                        ]">
                        <a :href="itemChild.linkUrl"  class="font-weight-semibold text-black d-flex" :target="'_blank'">
                           <img v-lazy="getIcon(itemChild.icona)" class="icon mr-1" v-if="itemChild.mostraImmagine" alt="icon" width="32" height="32"/>
                           <span>{{itemChild.nome}}</span>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>      
         </div>
      </div>
   </div>
</template>
<script>
   export default {
      name: "BloccoRaccolta",
      components:{
      },
      props: {
         articles: Array,
         color: String,
         background: String,
         parentUrl: String
      },
      beforeMount(){
      },
      methods:{
         getIcon(imgUrl){
            return process.env.VUE_APP_CDD_ASSETS + imgUrl;
         }
      },
      computed:{
         fontColorComp(){
            return (this.$props.color !== "#000") ? '#000' : '#000';
         },
         backGroundColorComp(){
            return (this.$props.background !== "#FFF") ?  '#FFF' : '#FFF';
         },            
      }, 
   };
</script>
<style scoped>
  .raccolta-image{max-width:100%; }
  .raccolta-image img{ max-height: 300px; object-fit: cover; }
  ul.raccolta{ padding:0px; list-style-type: none; width:100%; list-style-type: none;}
  ul.raccolta li{ display:inline-flex; padding:0.75rem 0px; font-size:18px;}
  ul.raccolta.columns li{font-size:16px; }
  ul.raccolta.columns li:nth-child(even){ clear:left; padding-left:0.75rem}

  @media only screen and (max-width: 882px) {
    ul.raccolta.columns li:nth-child(even){padding-left:unset;}
  }

</style>

