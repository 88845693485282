<template>

   <div class="video-container-iframe" v-if="embed && !inCard && !fb && !inSlider" :class="[{ ['listcard-iframe']: inList }, { ['mb-4']: videoSrc.didascalia !== null && inList && !inSlider }]">
      <iframe class="responsive-iframe" width="100%" height="420" :src="videoUrl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen :class="[{ ['listcard']: inList }]">
      </iframe>
   </div>
   <div class="img-wrapper position-relative" style="max-height:560px; overflow:hidden;" v-else-if="inCard && embed && !fb">
      <figure class="single-video mb-0">
         <img :src="posterUrl" :alt="videoSrc.descrizione != null ? videoSrc.descrizione : videoSrc.titolo" :title="videoSrc.descrizione != null ? videoSrc.descrizione : videoSrc.titolo" :class="[{ ['listcard']: inList }]" class="img-fluid sezioneImg video-card">
      </figure>
   </div>
   <template v-else-if="!inCard && embed && fb && !inSlider">
      <div class="video-container-iframe">
         <iframe class="responsive-iframe" :src="`${'https://www.facebook.com/plugins/video.php?height=314&href=' + videoUrl + '&show_text=false&width=560&t=0'}`" :width="`100%`" :height="560" :style="`border:none;overflow:hidden`" scrolling="no" frameborder="0" allowfullscreen="true" :allow="`autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share`"></iframe>
      </div>
   </template>
   <template v-else-if="inSlider">
      <div class="img-wrapper position-relative">
         <figure class="single-video mb-0">
            <img :src="posterUrl" :alt="videoSrc.descrizione != null ? videoSrc.descrizione : videoSrc.titolo" :title="videoSrc.descrizione != null ? videoSrc.descrizione : videoSrc.titolo" class="img-fluid sezioneImg">
         </figure>
      </div>
   </template>
   <figure class="single-video mb-0" v-else>
      <div class="video-container">
         <video controls="1" :poster="posterUrl !== null ? posterUrl : ''" :title="videoSrc.descrizione !== null ? videoSrc.descrizione : videoSrc.titolo" :class="[{ ['listcard']: inList }]" class="img-fluid sezioneImg video-card">
            <source :src="videoUrl" type="video/mp4">
         </video>
         <figcaption class="didascalia font-sans-serif mb-3" v-if="((videoSrc.didascalia !== null || videoSrc.titolo !== null || videoSrc.fonte !== null) && !inList && !inSlider) && !(inCard || embed)">
            <span class="d-block" v-if="videoSrc.didascalia !== null">{{ videoSrc.didascalia }}</span>
            <span class="d-block" v-if="videoSrc.titolo !== null">{{ videoSrc.titolo }}</span>
            <span class="d-block" v-if="videoSrc.fonte !== null"><b>Fonte </b><a :href="videoSrc.fonte" target="_blank" :title="`Fonte: ${videoSrc.fonte}`">{{ videoSrc.fonte }}</a></span>
         </figcaption>
      </div>
   </figure>
</template>

<script>
export default {
   name: "VideoComponent",
   components: {
   },
   props: {
      videoSrc: {
         type: [String, Object],
         description: "String del video"
      },
      inList: {
         type: Boolean,
         default: false
      },
      inSlider: {
         type: Boolean,
         default: false
      },
      inCard: {
         type: Boolean,
         default: false
      },
   },
   data() {
      return {
         videoUrl: '',
         embed: false,
         fb: false
      }
   },
   mounted() {
      let vidUrl = this.$props.videoSrc?.videoURL !== null && this.$props.videoSrc?.videoURL !== undefined ? this.$props.videoSrc.videoURL : this.$props.videoSrc.video[0];

      if (vidUrl.indexOf('youtube') > -1 || vidUrl.indexOf('youtu') > -1) {
         if (vidUrl.indexOf('watch') > -1) {
            this.videoUrl = "https://www.youtube.com/embed/" + (vidUrl.substring(vidUrl.indexOf('=') + 1));
            this.embed = true;
         }
         else {
            this.videoUrl = vidUrl;
            this.embed = true;
         }
      }
      else if (vidUrl.indexOf('facebook') > -1 || vidUrl.indexOf('facebook') > -1) {
         this.videoUrl = vidUrl;
         this.embed = true
         this.fb = true
      }
      else {
         if (vidUrl.indexOf('http') > -1) {
            this.videoUrl = vidUrl;
            this.embed = false;
         } else {
            this.videoUrl = process.env.VUE_APP_SQUIDEX_ASSETS + vidUrl;
            this.embed = false;
         }
      }
   },
   computed: {
      posterUrl() {
         if (this.$props.videoSrc === null) {
            return null;
         }

         if (this.$props.videoSrc.imgURL !== "" && this.$props.videoSrc.posterSrc === undefined && (this.$props.videoSrc.image === undefined || this.$props.videoSrc.image === null)) {
            return this.$props.videoSrc.imgURL
         }
         else if (this.$props.videoSrc.imgURL === undefined && this.$props.videoSrc.posterSrc !== undefined && (this.$props.videoSrc.image === undefined || this.$props.videoSrc.image === null)) {

            if (this.embed && !this.fb) { /* youtube */
               return 'https://img.youtube.com/vi_webp/__bVkzRq3Yw/sddefault.webp';
            }
            else {
               return this.$props.videoSrc.posterSrc
            }
         }
         else {
            if (this.$props.videoSrc.image !== null && this.$props.videoSrc.image.length > 0 && !this.$props.videoSrc.image[0].includes('http')) {
               return process.env.VUE_APP_SQUIDEX_ASSETS + this.$props.videoSrc.image[0]
            }
            else {
               if (this.$props.videoSrc.image.constructor === Array) {
                  return this.$props.videoSrc.image[0]
               }
               else {
                  return this.$props.videoSrc.image
               }
            }
         }
      }
   }
};
</script>
<style>
.video-container {
   overflow: hidden;
   position: relative;
   width: 100%;
}

.video-container iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.video-container video {
   width: 100%;
}

.video-container-iframe {
   position: relative;
   overflow: hidden;
   width: 100%;
   padding-top: 56.25%;
   /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   width: 100%;
   height: 100%;
}
</style>