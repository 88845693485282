<template>
   <transition name="modal-fade">
      <div class="mt-5">
         <div >
            <div class="input-group-append position-relative">
               <label for="field-area-cerca" class="sr-only d-none active" style="transition: none 0s ease 0s;">Cerca</label>

               <input type="search" id="field-cerca" placeholder="Cerca" class="form-control mt-auto" v-model="query" debounce="500" />

               <div class="input-group-append resetArea generic">
                  <button class="btn no-radius btn-minimal-padding reset" type="reset" @click="clearSearchArea" name="resetRicerca" area-label="Resettare la ricerca">
                     <svg class="icon icon-sm"><use xlink:href="@/assets/img/sprite.svg#it-close-big"></use></svg>
                  </button>
               </div>
               <button class="btn btn-primary btn-minimal-padding" name="applicaFiltriRicerca" @click="$emit('queryWatcher', this.query);" area-label="esegue la ricerca">
                  <svg class="icon icon-white icon-sm"><use xlink:href="@/assets/img/sprite.svg#it-search"></use></svg>
               </button>
            </div>
         </div>
      </div>          
   </transition>
</template>
<script>
   export default {
      name: "searchArea",
      props: {         
         title:{
            type: String,
            description: "titolo della ricerca"
         },
         inactive: {
            type: Boolean,
         },         
         init: {
            type: Boolean,
            default: true
         }
      },

      data() {
         return {
            query: "",
         };
      },
      
      methods: { 
         clearSearchArea(){
            this.query = '';
            this.argomento = [];
            this.$emit('clearSearch');
         },         
      },

      mounted(){      
         if(this.$route.query.q !== undefined || this.$route.query.keywords !== undefined || this.$route.params.keywords != ''){
            this.query = this.$route.query.q != '' ? this.$route.query.q : this.$route.query.keywords !== '' ? this.$route.query.keywords : this.$route.params.keywords;
         }       
      },
   };

</script>
<style>
#filterTabs .nav-item .nav-link{ width:100%; }
input[type="search"]:placeholder-shown + .input-group-append > .reset {
    display: none;
}
.close{position:absolute;right: 40px;top: 30px;}
.input-group-append .btn[type="reset"]{border-bottom: 1px solid #737272}
.btn-group{ display:inline-block; }
.btn.btn-chip:hover:not(.chip-disabled) {
   background: #F2F2F2;
   border-color: #005c99;
   transition: background-color 0.1s;
   text-decoration: none;
   font-weight: 600;
}

.btn.btn-chip {
   margin-bottom: 6px;
   display: none;
   justify-content: space-evenly;
   align-items: center;
   background: #fff;
   border: 1px solid #005c99;
   border-radius: 12px !important;
   color: #000;
   height: 24px;
   min-width: 100px;
   padding: 4px 32px 4px 32px !important;
   transition: all 0.05s;
   margin-right: 4px;
   font-size: 0.77778rem;
   text-decoration: none;
   font-weight: 600;
   line-height: 1.0rem;
   height: min-content;
   white-space: nowrap;
   box-shadow: unset !important;
}
    
.btn.btn-chip > span {
   font-size: 0.778rem;
   height: 16px;
   font-weight: 600;
   color: #000 !important;
   margin-bottom: 0;
   transform: translateY(-2px);
   transition: color 0.05s;
   text-decoration: none;    
   margin-left: -6px;
 }

.btn.btn-chip > span:after{
   content: "x";
   position: relative;
   right: -6px;
   top: -1px;
   font-weight: normal;
   color: #666;
   transform:scale(1.25, 1.1);
   cursor: pointer;
 }
.btn.btn-chip.active {
   display: inline-block;
}
</style>
