<template>
  <div class="row" :style="[`color:${this.$props.articles.fontColor};`]" v-if="post">
    <div class="col-12 col-md-12 col-lg-5 col-xl-4" v-if="articles.items[0].mostraImmagine && (post.image !== null || post.video !== null) && showImage">
      <template v-if="link !== null">
        <a :href="link" :title="articles.nome">
          <img v-lazy="getImageSrcTest(post)" class="img-fluid">
        </a>
      </template>
      <template v-else>
        <img v-lazy="getImageSrcTest(post)" class="img-fluid">
      </template>
    </div>
    <div class="col-12 col-md-12" :class="!articles.items[0].mostraImmagine || post.image == null ? 'col-lg-9 col-xl-9 mb-3' : 'col-lg-6 col-xl-6'" v-if="post.titolo !== null || post.breve !== null">
      <template v-if="link !== null">
        <a :href="link" :title="articles.nome" :style="[`color:${this.$props.articles.fontColor};`]">
          <h4 class="h3 mb-4">{{ post.titolo }}</h4>
          <h5 class="mb-4">{{ post.dataPubblicazione }}</h5>
          <p v-if="post.breve !== null">
            {{ post.breve }}
          </p>
        </a>
      </template>
      <template v-else>
        <h4 class="h3 mb-4">{{ post.titolo }}</h4>
        <h5 class="mb-4">{{ post.dataPubblicazione }}</h5>
        <p v-if="post.breve !== null">
          {{ post.breve }}
        </p>
      </template>
    </div>
  </div>
</template>
<script>
import { getPostsByIDs } from '@/service';

export default {
  name: "BloccoBanner",
  components: {
  },
  data() {
    return {
      post: null,
      showImage: true
    }
  },
  props: {
    articles: [Array, Object],
    parentUrl: String
  },
  created() {
    let ids = [];
    for (let item of this.$props.articles.items) {
      ids.push(item.idFonteDati);
      this.showImage = item.mostraImmagine
    }
    this.loadPosts(ids)
  },
  methods: {
    loadPosts: async function (ids) {
      this.posts = undefined;
      try {
        if (ids.length > 0) {
          const result = await getPostsByIDs(ids, true);
          this.post = result.posts[0];
        }
      } catch (ex) {
        this.post = null;
      }
    },

    getImageSrcTest: function (item) {

      if (item === null || (item.image === null && item.video === null)) {
        return;
      }
      else {

        if (item.video !== null) {
          return item.video.posterSrc
        }
        else {
          if (item.image.src.indexOf('unsplash') > -1) {
            return item.image.src + '&w=360&q=75'
          }
          else {
            return item.image.src;
          }
        }

      }
    }
  },
  computed: {
    link() {
      if (this.$props.articles.linkUrl !== undefined && this.$props.articles.linkUrl !== null) {
        return this.$props.articles.linkUrl
      }
      else if (this.post.linkList !== undefined && this.post.linkList.length > 0) {
        return this.post.linkList[0].linkURL
      }
      else {
        return null;
      }
    }
  }
};
</script>
<style scoped></style>
