<template>
   <div class="row" v-if="post">
      <div class="col-12 col-md-12 col-lg-6 col-xl-6 " v-if="post.image && post.image !== undefined && showImage">
         <router-link :to="'/novita/' + post.slug">
            <img :src="post.image.src" :alt="post.image.didascalia" class="w-100" />
         </router-link>
      </div>
      <div class="col-12 col-md-12 col-lg-6 col-xl-6 mt-4 mt-sm-0">
         <span class="date small">{{ post.dataPubblicazione }}</span>
         <router-link :to="'/novita/' + post.slug">
            <h3>{{ post.nome }}</h3>
            <p>{{ post.breve }}</p>
         </router-link>
      </div>
   </div>
   <div class="container" v-else-if="post === null">
      Loading...
   </div>
</template>

<script>
import { getPost } from '@/service';
import { nextTick } from 'vue';

export default {
   name: "BloccoSingolo",

   data() {
      return {
         post: [],
         showImage: true
      }
   },

   methods: {
      loadPost: async function (id) {
         this.post = undefined;
         try {
            const result = await getPost(id);
            this.post = result;
            this.$root.setTitle(this.post.titolo);
         } catch (ex) {
            this.post = null;
         }
      }
   },

   props: {
      article: Object,
      parentUrl: String
   },

   beforeMount() {
      let vm = this;
      nextTick(() => {
         this.loadPost(vm.article.idFonteDati)
         this.showImage = vm.article.mostraImmagine
      })
   },
   mounted() {
      let vm = this;
      nextTick(() => {
         this.loadPost(vm.article.idFonteDati)
         this.showImage = vm.article.mostraImmagine
      })
   },

   watch: {
   }
};
</script>
<style scoped></style>
