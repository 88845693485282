<template>
   <section class="striscia" :style="[`background: linear-gradient(${background} ${sectionBar}, #FFF 0%); color:${fontcolor};`]">
      <div class="container" :class="[
      tipologia.indexOf('banner') > -1 ? ' pt-4 pb-0' : ' py-4'
   ]">
         <h2 class="h3 font-weight-semibold mb-3" v-if="item.nome !== '' && item.nome !== undefined">
            <template v-if="item.linkUrl !== undefined && (item.linkType == 0 || item.linkType == 2)">
               <a :href="item.linkUrl" :title="item.nome" target="_blank" class="text-white" v-if="item.linkUrl.includes('http')">{{ item.nome }}</a>
               <router-link :to="{ path: item.linkUrl }" :style="[`color:${fontcolor};`]" v-if="!item.linkUrl.includes('http')">
                  {{ item.nome }}
               </router-link>
            </template>
            <template v-else>
               {{ item.nome }}
            </template>
         </h2>
         <BloccoBanner v-if="tipologia == 'banner'" :articles="item" :parentUrl="parentURL"></BloccoBanner>
         <BloccoBannerLarge v-else-if="tipologia == 'banner_large'" :articles="item" :parentUrl="parentURL"></BloccoBannerLarge>
         <BloccoChips v-else-if="tipologia == 'chips'" :articles="item" :parentUrl="parentURL"></BloccoChips>
         <BloccoListaContent v-else-if="tipologia == 'lista_contenuti'" :articles="item" :parentUrl="parentURL"></BloccoListaContent>
         <BloccoListaFiltrato v-else-if="tipologia == 'lista_tipo_contenuto_filtrato'" :articles="item"></BloccoListaFiltrato>
         <BloccoListaContentCarousel v-else-if="tipologia == 'lista_contenuti_carousel'" :articles="item" :parentUrl="parentURL"></BloccoListaContentCarousel>
         <BloccoListaContentCarouselSenzaTitolo v-else-if="tipologia == 'lista_contenuti_carousel_senza_titoli'" :articles="item" :parentUrl="parentURL"></BloccoListaContentCarouselSenzaTitolo>
         <BloccoListaContentImageSlider v-else-if="tipologia == 'lista_contenuti_images_slider'" :articles="item" :parentUrl="parentURL"></BloccoListaContentImageSlider>
         <BloccoListaContentSlider v-else-if="tipologia == 'lista_contenuti_slider'" :articles="item" :parentUrl="parentURL"></BloccoListaContentSlider>
         <BloccoListTitoli v-else-if="tipologia == 'lista_titoli'" :articles="item" :parentUrl="parentURL"></BloccoListTitoli>
         <BloccoRaccolta v-else-if="tipologia == 'lista_raccolte'" :color="fontcolor" :background="background" :articles="item.raccolteList" :parentUrl="parentURL"></BloccoRaccolta>
         <BloccoSingolo v-else :article="item.items[0]" :parentUrl="parentURL"></BloccoSingolo>

         <div class="text-right mt-3 mt-sm-1" v-if="item.linkType == 1 || item.linkType == 2">
            <a :href="item.linkUrl" :title="item.nome" target="_blank" class="font-weight-semibold small text-black text-underlined" v-if="item.linkUrl.includes('http')">Mostra tutti</a>
            <router-link :to="{ path: item.linkUrl }" class="font-weight-semibold small text-black text-underlined" v-else>
               Mostra tutti
            </router-link>
         </div>
      </div>
   </section>
</template>

<script>
import BloccoSingolo from './BloccoSingolo';
import BloccoBanner from './BloccoBanner';
import BloccoBannerLarge from './BloccoBannerLarge';
import BloccoChips from './BloccoChips';
import BloccoListaContent from './BloccoListaContent';
import BloccoListaContentCarousel from './BloccoListaContentCarousel';
import BloccoListaContentCarouselSenzaTitolo from './BloccoListaContentCarouselSenzaTitolo';
import BloccoListaFiltrato from './BloccoListaFiltrato';
import BloccoListaContentImageSlider from './BloccoListaContentImageSlider';
import BloccoListaContentSlider from './BloccoListaContentSlider';
import BloccoListTitoli from './BloccoListTitoli';
import BloccoRaccolta from './BloccoRaccolta';


export default {
   name: 'BaseBlock',
   components: {
      BloccoSingolo,
      BloccoBanner,
      BloccoBannerLarge,
      BloccoChips,
      BloccoListaContent,
      BloccoListaContentCarousel,
      BloccoListaContentCarouselSenzaTitolo,
      BloccoListaFiltrato,
      BloccoListaContentImageSlider,
      BloccoListaContentSlider,
      BloccoListTitoli,
      BloccoRaccolta,
   },
   data() {
      return {
         background: '#FFF',
         fontcolor: '#000',
         rowHeight: '200px',
         parentURL: null,
      }
   },
   props: {
      tipologia: {
         type: String,
         description: "Tipologia del card",
         required: true
      },
      item: {
         type: Object,
         description: "Articolo",
         required: true
      }
   },
   created() {
      this.background = this.item.palette
      this.fontcolor = this.item.fontColor
      this.parentURL = this.item.linkUrl !== undefined ? this.item.linkUrl : null

   },
   computed: {
      sectionBar() {
         let defaultHeight = this.$props.tipologia !== 'lista_raccolte' ? '200px' : '84px'
         if (this.$props.tipologia.indexOf('banner') > -1) {
            defaultHeight = '100%';
         }
         return defaultHeight

      }
   }
}   
</script>