<template>
   <div class="row mt-4">
      <div class="col-12 py-2" id="splider">
         <Splide region="carousel" :options="options" ref="carousel" aria-controls="#carouselTrack" @splide:move="call">
            <SplideSlide v-for="(item, index) in posts" :key="index" aria-hidden="false">
               <div class="row d-block-inline">
                  <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                     <router-link :to="`${pathSlug(item) + item.slug}`">
                        <template v-if="item.image && !item.video">
                           <ImageComponent :imageSrc="item.image" class="img-fluid" :inSlider="true" :index="index" />
                        </template>
                        <template v-if="!item.image && item.video">
                           <VideoComponent :videoSrc="item.video" :inSlider="true" />
                        </template>
                     </router-link>
                  </div>
                  <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                     <span class="date small">{{ item.dataPubblicazione }}</span>
                     <router-link :to="`${pathSlug(item) + item.slug}`">
                        <h3 class="mt-2 mt-sm-0">{{ item.titolo }}</h3>
                        <Markdown :source="item.breve" v-if="item.breve" />
                     </router-link>
                  </div>
               </div>
            </SplideSlide>
         </Splide>

         <div class="splide__track mt-2 mt-md-4">
            <ul class="d-flex flex-row justify-content-between p-0 list-group-horizontal splide__list" id="carouselTrack">
               <li v-for="(item, index) in posts" :key="index" @click="moveCarousel(index)" class="list-group-item p-0 border-0 splide__slide pt-3" :class="{ 'activeScroll': options.activeIndex == index }">
                  <div class="pr-3 small">
                     {{ $filters.fixStrLength(item.titolo, 120) }}
                  </div>
               </li>
            </ul>
         </div>

         <div className="mediaControls">
            <button class="splide__play d-none" id="splider_play" @click="togglePauseCarousel(0)" title="Avvia il carousel">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 18" aria-hidden="true">
                  <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
               </svg>
            </button>
            <button class="splide__pause" id="splider_pause" @click="togglePauseCarousel(1)" title="Ferma il carousel">
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-pause-fill" viewBox="0 0 16 18" aria-hidden="true">
                  <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
               </svg>
            </button>
         </div>
      </div>
   </div>
</template>
<script>
import '@splidejs/splide/dist/css/splide.min.css';
import Markdown from 'vue3-markdown-it';
import { getPostsByIDs } from '@/service';
import ImageComponent from '@/components/ImageComponent';
import VideoComponent from '@/components/VideoComponent';

export default {
   name: "BloccoListaContentCarousel",
   components: {
      Markdown,
      ImageComponent,
      VideoComponent
   },
   data() {
      return {
         options: {
            activeIndex: 0,
            autoplay: true,
            width: "100vw",
            arrows: false,
            rewind: true,
            padding: "0px",
            pagination: false,
            isNavigation: true,
            speed: 1000,
            interval: 7000,
            type: 'slide',
            lazyLoad: 'nearby',
            preloadPages: 1,
            perPage: 1,
         },
         posts: {},
         showImage: []
      }
   },
   mounted() {
   },
   created() {
      let ids = [];
      for (let item of this.$props.articles.items) {
         ids.push(item.idFonteDati)
         this.showImage.push(item.mostraImmagine)
      }
      this.loadPosts(ids)
   },
   methods: {
      moveCarousel(slide) {
         this.$refs.carousel.splide.go(slide);
         this.options.activeIndex = slide;
      },

      togglePauseCarousel(val) {
         let ePause = document.getElementById("splider_pause");
         let ePlay = document.getElementById("splider_play");

         if (val) {
            this.$refs.carousel.splide.Components.Autoplay.pause();
            ePlay.classList.remove('d-none')
            ePause.classList.add('d-none')
         } else {
            this.$refs.carousel.splide.Components.Autoplay.play();
            ePause.classList.remove('d-none')
            ePlay.classList.add('d-none')
         }
      },

      call() {
         this.options.activeIndex = this.$refs.carousel.splide.index;
      },

      getImageSrc(item) {
         if (item.video !== null) {
            return item.video.posterSrc !== undefined ? item.video.posterSrc : (item.video.imgURL !== null ? item.video.imgURL : item.video.image);
         }
         else {
            if (item.image.src.indexOf('unsplash') > -1) {
               return item.image.src
            }
            else {
               //return item.image.src +"width=540&height=406&mode=Max&auto=true&force=true";
               return item.image.src;
            }
         }
      },

      getImageText(item) {
         if (item.video !== null) {
            return item.video.titolo
         }
         else {
            if (item.image.src.indexOf('unsplash') > -1) {
               return item.image.titolo
            }
            else {
               return item.image.titolo;
            }
         }
      },

      loadPosts: async function (ids) {
         this.posts = undefined;
         try {
            //const result = await getPostsByIDs(ids, true);  
            const result = await getPostsByIDs(ids, true);
            this.posts = result.posts;
         } catch (ex) {
            this.posts = null;
         }
      },
      pathSlug(item) {
         if (item.schemaName.toLowerCase() === 'notizie') {
            return '/novita/notizie/notizia/';
         }
         else if (item.schemaName.toLowerCase() === 'evento') {
            return '/novita/eventi/evento/';
         }
         else {
            return '/novita/notizie/notizia/';
         }
      },
   },
   props: {
      articles: Object,
      parentUrl: String
   },
   computed: {
      linkNameNotizie() {
         let area = '';
         if (this.$props.articles.nome.toLowerCase() == 'notizie') {
            area = 'Notizie'
         }
         else if (this.$props.articles.nome.toLowerCase() == 'eventi') {
            area = 'Eventi'
         }
         return area;
      }
   }
};
</script>
<style scoped>
.mediaControls {
   position: absolute;
   top: -32px;
   right: 16px;
}

.mediaControls button {
   background-color: #D8D8D8;
   color: --900;
   border: 1px solid #4c4c4d;
   padding: 0px;
   height: 32px;
   width: 32px;
   box-shadow: 0 2px 4px darkgray;
   border-radius: 50%;
   cursor: pointer;
   transition: all 0.2s ease;
}

.mediaControls button:hover {
   background-color: #b1b1b3;
}

.mediaControls button:active {
   box-shadow: 0 0 2px #4c4c4d;
   transform: translateY(2px);
}

.splide--nav>.splide__slider>.splide__track>.splide__list>.splide__slide,
.splide--nav>.splide__track>.splide__list>.splide__slide,
.splide--nav>.splide__slider>.splide__track>.splide__list>.splide__slide.is-active,
.splide--nav>.splide__track>.splide__list>.splide__slide.is-active {
   border: 0px solid #FFF !important;
   margin-right: 1px;
}

.splide__list .list-group-item {
   flex: 1 1 0;
}

.splide__list .list-group-item h6 {
   font-size: 0.889rem;
   line-height: 28px;
   cursor: pointer;
   font-weight: 400 !important;
}

.splide__list .list-group-item::after {
   width: 33%;
   content: '';
   background-color: #D8D8D8;
   height: 6px;
   position: absolute;
   top: 0px;
}

.splide__list .list-group-item.activeScroll h6 {
   font-weight: 600 !important;
}

.splide__list .list-group-item.activeScroll::after {
   background-color: #8ECAE6;
}

@media screen and (max-width: 992px) {
   .splide__list .list-group-item {
      flex: 1 1 0;
   }

   .splide__list .list-group-item::after {
      width: 60%;
   }

   .splide__list .list-group-item {
      height: 0px;
      width: 15%;
   }
}
</style>
