<template>
   <div class="row">
      <template v-for="(item, index) in posts" :key="index">
         <BaseCard :tipologia="item.schemaName.toLowerCase()" :item="item" :showImage="showImage[index]" />
      </template>
   </div>
</template>
<script>
import BaseCard from '@/components/cards/BaseCard.vue';
import { getPostsByIDsGraph } from '@/service';

export default {
   name: "BloccoListaContent",
   components: {
      BaseCard
   },
   props: {
      articles: Object,
      parentUrl: String
   },
   data() {
      return {
         posts: [],
         showImage: []
      }
   },
   created() {
      let ids = [];
      for (let item of this.$props.articles.items) {
         ids.push(item.idFonteDati)
         this.showImage.push(item.mostraImmagine)
      }
      this.loadPosts(ids)
   },
   methods: {
      loadPosts: async function (ids) {
         this.posts = [];
         try {
            const result = await getPostsByIDsGraph(ids);
            this.posts = result;
         } catch (ex) {
            this.posts = [],
               this.showImage = []
         }
      },
   },
   computed: {
   },
};
</script>
<style scoped></style>
