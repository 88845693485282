<template>
   <div class="d-block" :class="{ 'mt-2': inCard, 'mt-4': !inCard,  }"> 
      <b v-if="inCard" class="lightgrey-color-a2">Struttura di riferimento</b>
      <h4 v-else id="responsabile">Struttura di riferimento</h4>

      <div v-if="inCard">
          <Chip :tagTitle="servizio" :url="parentUrl" />
      </div>
      <div class="d-block" v-else>
        <template v-for="(item, index) in areas" :key="`item-${index}`">
          <Chip :tagTitle="item" :isLinked="false"/>
        </template>
      </div>
   </div>
</template>

<script>
   import Chip from '@/components/Chip';   
   export default {
      name: "ServizioResponsabile",
      components:{
         Chip
      },
      props: {
         inCard: {
           type: Boolean,
           default: false,
         },
         servizio: {
           type: [String, Object],
           description: "Link type"
         }
      },
      data() {
         return{
            areas: [],
         }
      },
      methods:{
         getAreaServizi(obj){
            for ( var key in obj ) { // works for objects and arrays 
              var item = obj[key]; 
              if ( typeof item === "object" || Array.isArray(item) ) {
                  this.getAreaServizi(item)
              }
              else {
                if(key == 'nome'){
                  this.areas.push( item )
                }
              }
            } 
         },         
      },

      created(){
         this.getAreaServizi(this.$props.servizio)
      },

      computed:{
        parentUrl(){
          return this.$route.path + "?servizio_responabile="+encodeURIComponent(this.$props.servizio.trim())
        }
      }
   }
</script>