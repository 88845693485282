<template>
    <button @click="scrollTop" role="button" aria-label="Torna su" v-show="visible" class="ScrollToTop">
        <slot></slot>
    </button>
</template>

<script>
   export default {
      name: "ScrollToTop",
      data () {
         return {
            visible: false
         }
      },
      methods: {
         scrollTop: function () {
            this.intervalId = setInterval(() => {
               if (window.pageYOffset === 0) {
                  clearInterval(this.intervalId)
               }
               window.scroll(0, window.pageYOffset - 50)
            }, 20)
         },
         scrollListener: function () {
            this.visible = window.scrollY > 150
         }
      },
      mounted: function () {
         window.addEventListener('scroll', this.scrollListener)
      },
      beforeUnmount: function () {
         window.removeEventListener('scroll', this.scrollListener)
      }
   }
</script>
