<template>
   <!-- PAGINATION -->
   <div class="col-12 col-sm-9 my-4 d-flex justify-content-between ml-auto">
      <div class="flex-fill text-center d-block">
         <div class="d-block align-items-center d-flex justify-content-around ">
            <span class="pagination-summary">
               <b>{{ fromResult }}</b>-<b>{{ toResult }}</b> di <b>{{ pager.totalResults }} contenuti</b>
            </span>
         </div>

         <nav class="pagination-wrapper justify-content-center ml-3" aria-label="Navigazione centrata">
            <ul class="pagination mb-0">
               <li class="page-item next-item" v-if="currentPage > 1" @click="changePage(currentPage)">
                  <span class="page-link"><i class="fa fa-angle-left"></i></span>
               </li>

               <template v-if="totalPages < 9">
                  <li v-for="page in totalPages" :key="page" class="page-item" @click="changePage(page)" :class="{ 'active': pager.currentPage == page }" :title="`pagina ${page}`">
                     <span class="page-link">{{ page }}</span>
                  </li>
               </template>


               <template v-else>
                  <li class="page-item" @click="changePage(1)" :class="{ 'active': currentPage == 1 }" :title="`pagina 1`">
                     <span class="page-link">{{ 1 }}</span>
                  </li>

                  <template v-if="currentPage - 2 > 2">
                     <li class="page-item ml-0 mr-3 position-relative"><span class="ellipsis">...</span></li>
                  </template>

                  <template v-for="page in getPagerPages()" :key="page">
                     <li class="page-item" @click="changePage(page)" :class="{ 'active': currentPage == page }" :title="`pagina ${page}`" v-if="page !== 1">
                        <span class="page-link">{{ page }}</span>
                     </li>
                  </template>

                  <template v-if="currentPage + 2 < totalPages - 1">
                     <li class="page-item ml-0 mr-3 position-relative"><span class="ellipsis">...</span></li>
                  </template>

                  <li class="page-item" @click="changePage(totalPages)" :class="{ 'active': currentPage == totalPages }" :title="`pagina ${totalPages}`">
                     <span class="page-link">{{ totalPages }}</span>
                  </li>
               </template>

               <li class="page-item next-item" v-if="currentPage < totalPages" @click="changePage(currentPage + 1)">
                  <span class="page-link"><i class="fa fa-angle-right"></i></span>
               </li>
            </ul>
         </nav>
      </div>
      <div class="align-items-center d-flex flex-column">
         <div class="d-block"><span class="font-weight-semibold">elementi per pagina</span></div>
         <div class="w-100 mt-2">
            <b-form-select class="small w-100 form-control-sm small bg-white border-bottom ml-1 bordered form-select" value-field="value" text-field="text" v-model="selected" :options="options" @change="changeLimit" />
         </div>
      </div>
   </div>
   <!-- END PAGINATION -->
</template>
<script>
export default {
   name: "Pager",
   props: {
      pager: {
         type: Object,
      },
      maxResults: {
         type: Number,
         default: 12
      }
   },
   data() {
      return {
         selected: 12,
         options: [
            { value: 6, text: '6' },
            { value: 12, text: '12' },
            { value: 18, text: '18' },
            { value: 24, text: '24' },
         ],
         currentPage: this.$props.pager.currentPage,
         totalPages: Math.ceil(this.$props.pager.totalResults / this.$props.pager.limit)
      }
   },
   mounted() {
   },

   computed: {
      fromResult: function () {
         let fromResult = this.$props.pager.limit * (this.$props.pager.currentPage - 1) + 1;
         return (this.$props.pager.currentPage > this.totalPages) ? this.$props.pager.limit * (this.totalPages - 1) + 1 : fromResult;
      },
      toResult: function () {
         let toResult = (this.$props.pager.limit * this.$props.pager.currentPage);
         return this.$props.pager.totalResults > toResult ? toResult : this.$props.pager.totalResults;
      }
   },
   updated() {
      this.setTotalPages();
   },
   methods: {
      setTotalPages() {
         this.totalPages = Math.ceil(this.$props.pager.totalResults / this.$props.pager.limit)
      },
      changePage(event) {
         this.$emit('listenChangePage', event);
         this.currentPage = event
         this.intervalId = setInterval(() => {
            if (window.pageYOffset === 0) {
               clearInterval(this.intervalId)
            }
            window.scroll(0, window.pageYOffset - 50)
         }, 5)
      },
      changeLimit(event) {
         this.$emit('listenLimitChange', event);
      },
      getArrayFromDigit(start, end) {
         let digitArray = []
         for (let i = start; i < end; i++) {
            digitArray.push(i)
         }
         return digitArray
      },

      getPagerPages() {
         let paging = this.$data.totalPages > 2 ? this.getArrayFromDigit(2, this.$data.totalPages) : [2];
         if (this.$data.totalPages < 8) {
            return paging;
         }
         else {
            if (this.$data.currentPage > 3 && this.$data.currentPage + 2 > this.$data.totalPages) {
               return this.getArrayFromDigit(this.$data.currentPage - 2, this.$data.totalPages)
            }
            if (this.$data.currentPage < 3 && this.$data.currentPage + 4 < this.$data.totalPages) {
               return this.getArrayFromDigit(this.$data.currentPage, (this.$data.currentPage + 6 > this.$data.totalPages ? this.$data.totalPages : this.$data.currentPage + 6))
            }
            else {
               return this.getArrayFromDigit(this.$data.currentPage - 2, (this.$data.currentPage + 4 > this.$data.totalPages ? this.$data.totalPages : this.$data.currentPage + 3))
            }
         }
      }
   },
   watch: {
      selected(newVal) {
         this.currentPage = 1
         this.totalPages = Math.ceil(this.$props.pager.totalResults / newVal)
      }
   }
}
</script>
<style>
.pagination .page-item {
   cursor: pointer;
}

.pagination .page-item.active {
   border: 1px solid #023047;
   background-color: #023047;
   border-radius: 3px;
   color: #FFF;
   pointer-events: none;
}

.pagination .page-item.active .page-link {
   color: #FFF;
}

.pagination .ellipsis {
   position: absolute;
   bottom: 10px;
   margin-right: 0px;
   margin-left: 0px;
}
</style>