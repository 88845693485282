<template>
   <div class="row">
      <div class="col-12">
         <template v-for="(item, key) in posts" :key="key">  
             <span class="chip chip-default mx-1 my-1 small" v-if="!item.linkList[0].linkURL.startsWith('http')">               
                <router-link :to="item.linkList[0].linkURL" class="text-decoration-none chip-label">
                   <b>
                      {{item.titolo}}
                   </b>
                </router-link>
             </span>
             <span class="chip chip-default mx-1 my-1 small"  v-else>
               <a :href="item.linkList[0].linkURL" :title="item.titolo" target="_blank" class="chip-label" >
                  <b>
                     {{item.titolo}}
                  </b>               
               </a>
             </span>
         </template>
      </div>
   </div>
</template>
<script>
  import { getPostsByIDs } from '@/service';

   export default {
      name: "BloccoChips",
      components:{
      },
      data(){
        return{
          posts: null
        }
      },
      props: {
         articles: [Array, Object],
         parentUrl: String 
      },
      created(){
         let ids = [];
         for(let item of this.$props.articles.items){
            ids.push(item.idFonteDati);
         }
         this.loadPosts(ids)         
      },      
      methods:{
         loadPosts: async function(ids) {
            this.posts = undefined;
            try {
               const result = await getPostsByIDs(ids, true);  
               this.posts = result.posts;
            } catch (ex) {
               this.posts = null;
            }
         }           
      },
      computed:{

      }, 
   };
</script>
<style scoped>
</style>
