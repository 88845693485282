<template>
   <div class="row">
      <div class="col-12 py-2 mb-4">
         <Splide region="imgslider" :options="options" :extensions="extensions" ref="imgslider">
            <SplideSlide v-for="(item, index) in posts" :key="index">
               <div class="card-wrapper card-space">
                  <div class="card card-flex card-bg no-after" :class="[
            { ['card-with-image']: item.image !== null && item.image !== undefined && showImage[index] },
            { ['card-with-video']: item.video !== null && item.video !== undefined && showImage[index] },
            { ['card-with-video-embed']: item.video !== null && item.video !== undefined && item.video.videoURL.indexOf('you') > 1 && showImage[index] },
            { ['card-with-no-image']: ((item.image == null || item.image == undefined) && (item.video == null || item.video == undefined)) || !showImage[index] },
         ]">
                     <a :href="getLink(index)" v-if="getLink(index).includes('http')" target="_blank">
                        <figure v-if="item.image !== null && showImage[index]">
                           <img class="card-img" v-lazy="item.image.src" :alt="item.image.titolo" style="min-height:260px; min-width:360px; object-fit:cover;height:auto !important;" height="480" width="640" />
                           <figcaption>{{ item.titolo }}</figcaption>
                        </figure>
                        <div class="position-relative" v-else>
                           <h5 class="figcaption">{{ item.titolo }}</h5>
                        </div>
                     </a>
                     <router-link :to="{ path: getLink(index), props: { id: item.id, article: item } }" v-else>
                        <figure v-if="item.image !== null && showImage[index]">
                           <img class="card-img" v-lazy="item.image.src" :alt="item.image.titolo" style="min-height:260px; min-width:360px; object-fit:cover;height:auto !important;" height="480" width="640" />
                           <figcaption>{{ item.titolo }}</figcaption>
                        </figure>
                        <div class="position-relative" v-else>
                           <h5 class="figcaption">{{ item.titolo }}</h5>
                        </div>
                     </router-link>
                  </div>
               </div>
            </SplideSlide>
         </Splide>
      </div>
   </div>
</template>
<script>
import { getPostsByIDs } from '@/service';
import { Grid } from "@splidejs/splide-extension-grid";
//import Card from '@/components/cards/BaseCard.vue';   

export default {
   name: "BloccoListaContentImageSlider",
   components: {
      //   BaseCard
   },

   data() {
      return {
         options: {
            autoplay: false,
            width: "100vw",
            height: "100%",
            arrows: false,
            gap: '1rem',
            padding: { left: 0, right: 0, top: 0, bottom: 30 },
            pagination: true,
            perPage: this.$props.articles.numContenutiRiga,
            lazyLoad: 'nearby',
            preloadPages: 1,
            grid: {
               /*  cols     : this.$props.articles.numContenutiRiga,
                 rows     : Math.ceil(this.$props.articles.numContenutiPagina / this.$props.articles.numContenutiRiga),                
                 gap: {
                   row    : "1rem",
                   col    : "1rem",
                 },*/
            },
            type: 'slide',

            breakpoints: {
               992: {
                  perPage: 2,
                  pagination: true,
                  grid: false,
               },
               560: {
                  perPage: 1,
                  pagination: true,
                  grid: false,
               },
            },
            classes: {
               page: 'bulletPager', // each button                  
            }
         },
         extensions: { Grid },
         ids: [],
         posts: {},
         showImage: []
      }
   },
   props: {
      articles: Object,
      parentUrl: String
   },
   methods: {
      loadPosts: async function (ids) {
         this.posts = undefined;
         try {
            const result = await getPostsByIDs(ids, true);
            this.posts = result.posts;
         } catch (ex) {
            this.posts = null;
         }
      },
      getLink(i) {
         return this.posts[i].linkList[0].linkURL;
      }
   },
   created() {
      try {
         this.options.perMove = this.$props.articles.numContenutiRiga
         this.options.perPage = this.$props.articles.numContenutiRiga
         this.options.pagination = this.$props.articles.items.length > this.$props.articles.numContenutiRiga ? true : false


         for (let item of this.$props.articles.items) {
            this.ids.push(item.idFonteDati);
            this.showImage.push(item.mostraImmagine)
         }
      }
      catch (error) {
         console.log(error);
      }
      finally {
         this.loadPosts(this.ids);
      }
   }
};
</script>

<style>
.card-with-image figure {
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden
}

.card-with-image figure img {
   flex-shrink: 0;
   min-width: 100%;
   min-height: 100%
}

.splide__sr {
   display: none !important;
}

.splide__pagination {
   position: relative !important;
   padding: 0px;
   bottom: unset;
}

.splide__pagination li {}

.card-img {
   max-height: 260px;
}

.bulletPager {
   width: 16px;
   height: 16px;
   border-radius: 50%;
   cursor: pointer;
   position: relative;
   margin: auto 12px;
   border: 1px solid #219EBC;
   background-color: #FFF;
   transition: 0.3s;
}

.bulletPager:hover {
   background-color: #8ECAE6;
}

.bulletPager.is-active {
   background-color: rgba(33, 158, 188, 0.9);
}
</style>